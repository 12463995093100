import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./styles/shareCodeInput.css";
import ShareableService from "src/app/services/shareable.service";
import { useNavigate } from "react-router-dom";

export default function ShareCodeInput() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [keyCode0, setKeyCode0] = useState('');
    const [keyCode1, setKeyCode1] = useState('');
    const [keyCode2, setKeyCode2] = useState('');
    const [keyCode3, setKeyCode3] = useState('');
    const [keyCode4, setKeyCode4] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    useEffect(() => {
        window.addEventListener("paste", (ev) => {
            ev.preventDefault();

            let clipboardContents = ev.clipboardData?.getData("text").trim();

            if (clipboardContents?.length !== 5) return;

            for (let i = 0; i < clipboardContents.length; i++) {
                if (!allowedCodeKeys.test(clipboardContents[i])) return;
            }

            setKeyCode0(clipboardContents[0]);
            setKeyCode1(clipboardContents[1]);
            setKeyCode2(clipboardContents[2]);
            setKeyCode3(clipboardContents[3]);
            setKeyCode4(clipboardContents[4]);
        });
    }, []);

    const allowedCodeKeys = /[2-9A-HJ-NP-Za-hj-np-z]/;

    function onCodeChange(ev: ChangeEvent<HTMLInputElement>, codeIdx: number) {
        const target = ev.target.value;

        if (target.length > 1) return;
        
        switch (codeIdx) {
            case 0:
                if (target === '' || (keyCode0 === '' && allowedCodeKeys.test(target))) {
                    setKeyCode0(target);

                    setSubmitError(false);
                    if (target.length > 0) findAndFocusNextInput(0);
                }
                break;
            case 1:
                if (target === '' || (keyCode1 === '' && allowedCodeKeys.test(target))) {
                    setKeyCode1(target);

                    setSubmitError(false);
                    if (target.length === 0) findAndFocusPreviousInput(1);
                    else if (target.length > 0) findAndFocusNextInput(1);
                }
                break;
            case 2:
                if (target === '' || (keyCode2 === '' && allowedCodeKeys.test(target))) {
                    setKeyCode2(target);

                    setSubmitError(false);
                    if (target.length === 0) findAndFocusPreviousInput(2);
                    else if (target.length > 0) findAndFocusNextInput(2);
                }
                break;
            case 3:
                if (target === '' || (keyCode3 === '' && allowedCodeKeys.test(target))) {
                    setKeyCode3(target);

                    setSubmitError(false);
                    if (target.length === 0) findAndFocusPreviousInput(3);
                    else if (target.length > 0) findAndFocusNextInput(3);
                }
                break;
            case 4:
                if (target === '' || (keyCode4 === '' && allowedCodeKeys.test(target))) {
                    setKeyCode4(target);

                    setSubmitError(false);
                    if (target.length === 0) findAndFocusPreviousInput(4);
                }
                break;
        }
    }

    function findAndFocusPreviousInput(currentIdx: number) {
        const prevInput = document.querySelector(`input[name=keyCode${currentIdx - 1}]`) as HTMLInputElement;

        if (prevInput) prevInput.focus();
    }

    function findAndFocusNextInput(currentIdx: number) {
        const nextInput = document.querySelector(`input[name=keyCode${currentIdx + 1}]`) as HTMLInputElement;

        if (nextInput) nextInput.focus();
    }

    function onKeyUp(ev: React.KeyboardEvent<HTMLInputElement>, codeIdx: number) {
        if (ev.key === 'Enter' && validateCode()) {
            submit();
            return;
        }

        if (ev.key !== 'Backspace' || codeIdx === 0) return;

        switch (codeIdx) {
            case 1:
                if (keyCode1 !== '') return;
                break;
            case 2:
                if (keyCode2 !== '') return;
                break;
            case 3:
                if (keyCode3 !== '') return;
                break;
            case 4:
                if (keyCode4 !== '') return;
                break;
        }

        findAndFocusPreviousInput(codeIdx);
    }

    function onKeyDown(ev: React.KeyboardEvent<HTMLInputElement>, codeIdx: number) {
        const keyDownEvents = [
            'ArrowLeft', 'ArrowRight'
        ];

        if (keyDownEvents.indexOf(ev.key) < 0) return;
        const input = document.querySelector(`input[name=keyCode${codeIdx}]`) as HTMLInputElement;
        if (!input) return;

        if (ev.key === 'ArrowLeft' && codeIdx !== 0 && input.selectionEnd === 0) findAndFocusPreviousInput(codeIdx);
        if (ev.key === 'ArrowRight' && codeIdx !== 4 && input.selectionEnd === input.value.length) findAndFocusNextInput(codeIdx);
    }

    function validateCode(): boolean {
        return (
            keyCode0 !== '' &&
            keyCode1 !== '' &&
            keyCode2 !== '' &&
            keyCode3 !== '' &&
            keyCode4 !== ''
        );
    }

    function getCode(): string {
        return `${keyCode0}${keyCode1}${keyCode2}${keyCode3}${keyCode4}`.toUpperCase();
    }

    function submit() {
        if (validateCode()) {
            setSubmitting(true);
            setSubmitError(false);

            ShareableService().validateCode(getCode())
                .then(res => {
                    if (res.data.valid) {
                        navigate(`/video/${getCode()}`);
                    } else {
                        setSubmitting(false);
                        setSubmitError(true);
                    }
                })
                .catch(err => {
                    setSubmitting(false);
                    setSubmitError(true);
                });
        }
    }

    return (
        <>
        <div className="d-flex justify-content-center">
            <input type="text" name="keyCode0" className={`code-input form-control ${submitError && "error"}`} value={keyCode0} onChange={e => onCodeChange(e, 0)} onKeyUp={e => onKeyUp(e, 0)} onKeyDown={e => onKeyDown(e, 0)} />
            <input type="text" name="keyCode1" className={`code-input form-control ${submitError && "error"}`} value={keyCode1} onChange={e => onCodeChange(e, 1)} onKeyUp={e => onKeyUp(e, 1)} onKeyDown={e => onKeyDown(e, 1)} />
            <input type="text" name="keyCode2" className={`code-input form-control ${submitError && "error"}`} value={keyCode2} onChange={e => onCodeChange(e, 2)} onKeyUp={e => onKeyUp(e, 2)} onKeyDown={e => onKeyDown(e, 2)} />
            <input type="text" name="keyCode3" className={`code-input form-control ${submitError && "error"}`} value={keyCode3} onChange={e => onCodeChange(e, 3)} onKeyUp={e => onKeyUp(e, 3)} onKeyDown={e => onKeyDown(e, 3)} />
            <input type="text" name="keyCode4" className={`code-input form-control ${submitError && "error"}`} value={keyCode4} onChange={e => onCodeChange(e, 4)} onKeyUp={e => onKeyUp(e, 4)} onKeyDown={e => onKeyDown(e, 4)} />
        </div>

        {submitError && 
            <div className="invalid-code mt-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                </svg>
                <span className="ms-2 text-white">{t("invalid_code")}</span>
            </div>
        }

        <div className="d-flex justify-content-center m-4">
            <button className="btn btn-primary btn-submit" disabled={!validateCode()} onClick={submit}>
            {submitting && <div className="spinner-border text-white"></div>}
            {!submitting && <span>{t("submit")}</span>}
            </button>
        </div>
        <p className="small mt-2 text-center">
            {t("obtain_code")}
        </p>
        </>
    );
}