export default function AppConfig() {
    const urlParams = new URLSearchParams(window.location.search);
    const regionParam = urlParams.get('region') ?? 'eu-west-2';

    const euServices = {
        shareableApi: process.env['SHAREABLE_API'],
        witnessApi: process.env['WITNESS_API']
    };
    const usServices = {
        shareableApi: process.env['SHAREABLE_API_US'],
        witnessApi: process.env['WITNESS_API_US']
    }

    switch (regionParam) {
        case 'us-east-2':
            return usServices;
        default:
            return euServices;
    }
}