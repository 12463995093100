import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
    .use(initReactI18next)
    .use(HttpApi)
    .use(LanguageDetector)
    .init({
        supportedLngs: ["en", "en-US", "de", "es", "fr","pl","ro","nl","sv","cs","no","da","fi",],
        fallbackLng: "en-GB",
        nonExplicitSupportedLngs: true,
        interpolation: {
            escapeValue: false
        },
        debug: process.env['NODE_ENV'] === "development",
        backend: {
            loadPath: '/locales/{{ns}}.{{lng}}.json'
        }
    });

export default i18next;
