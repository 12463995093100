import { Link } from "react-router-dom"
import IQLogo from "../components/shared/IQLogo"
import Header from "../components/header";
import Footer from "../components/footer";

import "./styles/notFound.css";
import { useTranslation } from "react-i18next";

export default function NotFound() {
    const { t } = useTranslation();

    return (
        <>
        <Header />
        <div className="page-view not-found">
            <div className="container">
                <div className="d-flex justify-content-center">
                    <div className="col-md-5 p-3 code-view">
                        <div className="iq-logo">
                            <IQLogo />

                            <div className="d-flex justify-content-center mb-3">
                                <h2>{t("not_found")}</h2>
                            </div>
                        </div>

                        <div className="mb-3">
                            <p className="small mt-2 text-center">
                                <Link to="/">{t("back_to_home")}</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    );
}