import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { VideoJS } from "src/app/components/shared/videoJS";
import { Direction, StreamLink, VideoPlayers, parseStringToDirection } from "src/app/model/video.link";
import WitnessService from "src/app/services/witness.service";
import Player from "video.js/dist/types/player";
import videojs from "video.js";

import "./styles/witness.replay.css";

export default function WitnessReplay() {
    const { t } = useTranslation();
    const { sessionId } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [loadError, setLoadError] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    let players: VideoPlayers[] = [];
    let retryAttempt: number = 0;

    if (loadError) throw new Response("Not Found", { status: 404 });

    const [views, setViews] = useState<StreamLink[]>([
        { title: t('front'), lastThumbnail: '', direction: Direction.Front, streamUrl: '' },
        { title: t('cabin'), lastThumbnail: '', direction: Direction.Cabin, streamUrl: '' },
        { title: t('rear'), lastThumbnail: '', direction: Direction.Rear, streamUrl: '' }
    ]);

    function getView(direction: Direction): StreamLink | undefined {
        return views.find(view => view.direction === direction);
    }

    if (searchParams.size) window.history.replaceState(null, "", `/witness/${sessionId}?${searchParams.toString()}`);
    else window.history.replaceState(null, "", `/witness/${sessionId}`);

    function teardown() {
        document.querySelector("body")!.className = "default-body";
    }

    useEffect(() => {
        document.querySelector("body")!.className = "dark-body";
        
        tryGetReplayUrls();
        
        return () => teardown();
    }, [sessionId]);

    function tryGetReplayUrls() {
        const viewsCopy = views.map(view => view);

        WitnessService().getReplayUrls(sessionId!, videojs.browser.IS_ANY_SAFARI)
            .then(res => {
                res.data.forEach(stream => {
                    let idx = viewsCopy.findIndex(view => view.direction === parseStringToDirection(stream.direction));
                    if (idx > -1) {
                        viewsCopy[idx].streamUrl = stream.streamURL;
                    }
                });

                setViews(viewsCopy);
                setLoading(false);
            })
            .catch(err => {
                if (retryAttempt >= 5) setLoadError(true);
                else {
                    retryAttempt++;
                    setTimeout( () => tryGetReplayUrls(), (retryAttempt * 500)); // increase wait before retry 5 seconds each time
                }
            });
    }

    function onPlayerReady(player: Player, direction: Direction) {
        players.push({
            player,
            direction
        });

        player.play();

        if (direction === Direction.Front) {
            player.on('pause', () => pauseSecondaryViews());
            player.on('play', () => playSecondaryViews());
            player.on('seeking', () => seekSecondaryViews());
            player.on('error', () => setLoadError(true));
        }
    }

    function seekSecondaryViews() {
        const seekToTime = players.find(player => player.direction === Direction.Front)?.player?.currentTime();

        players.filter(player => player.direction !== Direction.Front).forEach(player => {
            player.player.currentTime(seekToTime);
        });
    }

    function pauseSecondaryViews() {
        players.filter(player => player.direction !== Direction.Front).forEach(player => {
            player.player.pause();
        });
    }

    function playSecondaryViews() {
        players.filter(player => player.direction !== Direction.Front).forEach(player => {
            player.player.play();
        });
    }

    return (
        <div className="page-view witness-replay mt-4">
            <div className="container">
                <div className="row">
                    <div className="front-stream-viewer col-md-8 col-12">
                        <div className="player-wrap witness-replay mb-3">
                            <h5 className="mb-2 ms-2">{t("stream_title", {camera: getView(Direction.Front)})}</h5>
                            {
                                getView(Direction.Front)?.streamUrl !== '' && 
                                    <div className="view-box">
                                        <VideoJS options={{
                                            autoplay: "muted",
                                            controls: true,
                                            fluid: true,
                                            responsive: true,
                                            sources: [
                                                { src: getView(Direction.Front)!.streamUrl, type: videojs.browser.IS_ANY_SAFARI ? 'application/x-mpegURL' : 'application/dash+xml' }
                                            ]
                                        }} onReady={player => onPlayerReady(player, Direction.Front)} />
                                    </div>
                            }
                            {
                                getView(Direction.Front)?.streamUrl === '' && 
                                    <div className="no-video d-flex align-items-center justify-content-center"> 
                                        {!loading && <h6>{t("no_camera", {camera: getView(Direction.Front)})}</h6>}
                                        {loading && <div className="spinner-border text-white"></div>}
                                    </div>
                            }
                        </div>
                        
                    </div>
                    <div className="col-md-4 col-12">
                        
                        <div className="side-bar px-3 pt-1 pb-3">
                
                            <div className="view-wrap mt-3">
                                <h6 className="mb-2">{t("stream_title", {camera: getView(Direction.Cabin)})}</h6>
                                {
                                    getView(Direction.Cabin)?.streamUrl !== '' && 
                                        <div className="view-box">
                                            <div>
                                                <VideoJS options={{
                                                    autoplay: "muted",
                                                    controls: false,
                                                    fluid: true,
                                                    responsive: true,
                                                    sources: [
                                                        { src: getView(Direction.Cabin)!.streamUrl, type: videojs.browser.IS_ANY_SAFARI ? 'application/x-mpegURL' : 'application/dash+xml' }
                                                    ]
                                                }} onReady={player => onPlayerReady(player, Direction.Cabin)} />
                                            </div>
                                        </div>
                                }
                            
                                {
                                    getView(Direction.Cabin)?.streamUrl === '' && 
                                        <div className="not-avail d-flex align-items-center justify-content-center">
                                            <div>
                                                {!loading && <h6>{t("no_camera", {camera: getView(Direction.Cabin)})}</h6>}
                                                {loading && <div className="spinner-border text-white"></div>}
                                            </div>
                                        </div>
                                }
                            </div>

                            <div className="view-wrap mt-3">
                                <h6 className="mb-2">{t("stream_title", {camera: getView(Direction.Rear)})}</h6>
                                {
                                    getView(Direction.Rear)?.streamUrl !== '' && 
                                        <div className="view-box">
                                            <div>
                                                <VideoJS options={{
                                                    autoplay: "muted",
                                                    controls: false,
                                                    fluid: true,
                                                    responsive: true,
                                                    sources: [
                                                        { src: getView(Direction.Rear)!.streamUrl, type: videojs.browser.IS_ANY_SAFARI ? 'application/x-mpegURL' : 'application/dash+xml' }
                                                    ]
                                                }} onReady={player => onPlayerReady(player, Direction.Rear)} />
                                            </div>
                                        </div>
                                }
                            
                                {
                                    getView(Direction.Rear)?.streamUrl === '' && 
                                        <div className="not-avail d-flex align-items-center justify-content-center">
                                            <div>
                                                {!loading && <h6>{t("no_camera", {camera: getView(Direction.Rear)})}</h6>}
                                                {loading && <div className="spinner-border text-white"></div>}
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}