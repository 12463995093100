import axios, { AxiosResponse } from "axios";
import AppConfig from "../util/app.config";
import { AssetLink, ValidateResponse } from "../model/video.link";

export default function ShareableService() {
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    function getLinks(code: string): Promise<AxiosResponse<AssetLink[]>> {
        return axios.get<AssetLink[]>(`${AppConfig().shareableApi}/videoportal/video-thumbnails/${code}`);
    }

    function getVideos(code: string): Promise<AxiosResponse<AssetLink[]>> {
        return axios.get<AssetLink[]>(`${AppConfig().shareableApi}/videoportal/videos/${code}`);
    }

    function validateCode(code: string): Promise<AxiosResponse<ValidateResponse>> {
        return axios.post<ValidateResponse>(`${AppConfig().shareableApi}/videoportal/validate-code`, {code});
    }

    return {
        getLinks,
        getVideos,
        validateCode
    };
}