import { Link } from "react-router-dom"
import IQLogo from "../components/shared/IQLogo"
import Header from "../components/header";
import Footer from "../components/footer";

import "./styles/auth.css";
import { useTranslation } from "react-i18next";

export default function Auth() {
    const { t } = useTranslation();

    return (
        <div className="page-view auth-success">
            <div className="container">
                <div className="d-flex justify-content-center">
                    <div className="col-md-5 p-3 code-view">
                        <div className="iq-logo">
                            <IQLogo />

                            <div className="d-flex justify-content-center mb-3">
                                <h2>{t("success")}</h2>
                            </div>
                        </div>

                        <div className="mb-3">
                            <p className="small mt-2 text-center">
                                {t("wait_for_redirect")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}