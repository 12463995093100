import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom"
import WitnessService from "src/app/services/witness.service";

export default function WitnessInvite() {
    const { t } = useTranslation();
    const { contactId } = useParams();
    const [loading, setLoading] = useState(true);
    const [acceptSuccess, setAcceptSuccess] = useState(false);
    const [inviteByUser, setInviteByUser] = useState('iQ');

    function teardown() {
        document.querySelector("body")!.className = "default-body";
    }

    useEffect(() => {
        document.querySelector("body")!.className = "dark-body";
        
        WitnessService().acceptInvite(contactId!)
            .then(res => {
                setInviteByUser(res.data.invitedBy);
                setAcceptSuccess(true);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })
        
        return () => teardown();
    }, []);

    return (
        <div className="page-view">
            <div className="container">

                <div className="d-flex justify-content-center">
                {
                    loading && 
                        <div className="col-md-5 p-3 code-view">
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-white"></div>
                            </div>
                        </div>
                }

                {
                    !loading && 
                        <div className="col-md-5 p-3 code-view">
                            <div className="iq-logo">
                                <div className="d-flex justify-content-center">
                                    {
                                        acceptSuccess &&
                                            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="57" viewBox="0 0 56 57" fill="none">
                                                <path
                                                    d="M28 0.875C35.4261 0.875 42.548 3.82499 47.799 9.07601C53.05 14.327 56 21.4489 56 28.875C56 36.3011 53.05 43.423 47.799 48.674C42.548 53.925 35.4261 56.875 28 56.875C20.5739 56.875 13.452 53.925 8.20101 48.674C2.94999 43.423 0 36.3011 0 28.875C0 21.4489 2.94999 14.327 8.20101 9.07601C13.452 3.82499 20.5739 0.875 28 0.875ZM24.512 34.399L18.292 28.175C18.069 27.952 17.8043 27.7751 17.513 27.6545C17.2216 27.5338 16.9093 27.4717 16.594 27.4717C16.2787 27.4717 15.9664 27.5338 15.675 27.6545C15.3837 27.7751 15.119 27.952 14.896 28.175C14.4457 28.6253 14.1927 29.2361 14.1927 29.873C14.1927 30.5099 14.4457 31.1207 14.896 31.571L22.816 39.491C23.0384 39.7151 23.3029 39.893 23.5943 40.0144C23.8857 40.1357 24.1983 40.1982 24.514 40.1982C24.8297 40.1982 25.1423 40.1357 25.4337 40.0144C25.7251 39.893 25.9896 39.7151 26.212 39.491L42.612 23.087C42.838 22.8649 43.0177 22.6003 43.1409 22.3085C43.2641 22.0166 43.3283 21.7032 43.3298 21.3864C43.3312 21.0696 43.27 20.7556 43.1495 20.4626C43.029 20.1696 42.8517 19.9033 42.6278 19.6792C42.404 19.455 42.1379 19.2774 41.845 19.1566C41.5522 19.0358 41.2383 18.9741 40.9215 18.9752C40.6046 18.9763 40.2912 19.0402 39.9992 19.163C39.7071 19.2858 39.4423 19.4653 39.22 19.691L24.512 34.399Z"
                                                    fill="#34C759" />
                                            </svg>
                                    }
                                    {
                                        !acceptSuccess &&
                                            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="57" viewBox="0 0 56 57" fill="none">
                                                <path
                                                    d="M28 56.875C12.6443 56.875 0 44.2307 0 28.875C0 13.5461 12.6443 0.875 27.9732 0.875C43.3289 0.875 56 13.5461 56 28.875C56 44.2307 43.3557 56.875 28 56.875ZM28 33.2508C29.6644 33.2508 30.6577 32.3112 30.7114 30.5663L31.1409 17.8951C31.1946 16.0965 29.8792 14.8079 27.9732 14.8079C26.0671 14.8079 24.7785 16.0696 24.8591 17.8683L25.2617 30.5663C25.3154 32.3112 26.3087 33.2508 28 33.2508ZM28 42.7005C29.9597 42.7005 31.5168 41.3851 31.5168 39.479C31.5168 37.5998 29.9597 36.2575 28 36.2575C26.0403 36.2575 24.5101 37.5998 24.5101 39.479C24.5101 41.3582 26.0671 42.7005 28 42.7005Z"
                                                    fill="#F2821B" />
                                            </svg>
                                    }
                                </div>

                                <div className="d-flex justify-content-center mb-3">
                                    {acceptSuccess && <h2>{t("success")}</h2>}
                                    {!acceptSuccess && <h2>{t("invite_not_found")}</h2>}
                                </div>
                            </div>

                            <div className="mb-3">
                            {
                                acceptSuccess && 
                                    <p className="small mt-2 text-center">
                                        <Trans i18nKey="invite_success_body" values={{inviteByUser}} />
                                    </p>
                            }
                            {
                                !acceptSuccess && 
                                    <p className="small mt-2 text-center">
                                        {t("invite_not_found_body")}
                                    </p>
                            }
                            <p className="small mt-2 text-center">
                                <a href="https://nextbase.co.uk/iq-experience" target="_blank">{t("learn_more")}</a> <svg width="24" height="25" viewBox="0 0 24 25"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="arrow_forward" clip-path="url(#clip0_32345_10162)">
                                    <path id="Vector"
                                    d="M5 13.8752H16.17L11.29 18.7552C10.9 19.1452 10.9 19.7852 11.29 20.1752C11.68 20.5652 12.31 20.5652 12.7 20.1752L19.29 13.5852C19.68 13.1952 19.68 12.5652 19.29 12.1752L12.71 5.57521C12.32 5.18521 11.69 5.18521 11.3 5.57521C10.91 5.96521 10.91 6.59522 11.3 6.98522L16.17 11.8752H5C4.45 11.8752 4 12.3252 4 12.8752C4 13.4252 4.45 13.8752 5 13.8752Z"
                                    fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_32345_10162">
                                    <rect width="24" height="24" fill="white" transform="translate(0 0.875)" />
                                    </clipPath>
                                </defs>
                                </svg>
                            </p>
                            </div>
                        </div>
                    }
                </div>
            </div>
            </div>
    );
}