import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function Root() {
    const { t } = useTranslation();
    const location = useLocation();

    function getLocalisedServiceForPath(pathName: string): string {
        if (pathName.indexOf("witness") > -1) return t("witness_mode");

        return "Nextbase";
    }

    useEffect(() => {
        document.title = t("title", { service: getLocalisedServiceForPath(location.pathname) });
    }, [location]);

    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}