// /// <reference types="@angular/localize" />

// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppModule } from './app/app.module';


// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));

import { createRoot } from 'react-dom/client';
import * as React from "react";
import App from './app/app';

import "./locale/i18n";

const domNode = document.getElementById('root')!;
const root = createRoot(domNode);
root.render(
    <React.StrictMode>
        <React.Suspense fallback="Loading...">
            <App />
        </React.Suspense>
    </React.StrictMode>
);