import { useEffect, useRef } from "react"
import videojs from "video.js";
import Player from "video.js/dist/types/player";

import "video.js/dist/video-js.css";

export interface PlayerSource {
    src: string;
    type: string;
};

export interface PlayerOptions {
    controls: boolean;
    fluid: boolean;
    autoplay: boolean | "muted";
    responsive: boolean;
    sources: PlayerSource[];
};

export const VideoJS = (props: { options: PlayerOptions, onReady: (player: Player) => void, canPause?: boolean }) => {
    const videoRef = useRef<HTMLDivElement | null>(null);
    const playerRef = useRef<Player | null>(null);
    const {options, onReady, canPause} = props;

    const staticOptions = {
        disablePictureInPicture: true,
        controlBar: {
            pictureInPictureToggle: false,
            playToggle: canPause ?? true
        },
        preload: "auto",
        html5: {
            hls: {
                overrideNative: !videojs.browser.IS_ANY_SAFARI
            },
            vhs: {
                overrideNative: !videojs.browser.IS_ANY_SAFARI
            },
            nativeAudioTracks: videojs.browser.IS_ANY_SAFARI,
            nativeVideoTracks: videojs.browser.IS_ANY_SAFARI,
            nativeTextTracks: videojs.browser.IS_ANY_SAFARI
        }
    };

    useEffect(() => {
        if (!playerRef.current) {
            const videoElement = document.createElement("video-js");
            videoElement.classList.add('vjs-big-play-centered', 'video-player');
            if (videojs.browser.IS_ANY_SAFARI) videoElement.setAttribute("playsinline", "");
            videoRef.current?.appendChild(videoElement);

            const player = playerRef.current = videojs(videoElement, {...options, ...staticOptions}, () => {
                videojs.log('player is ready');
                onReady && onReady(player);
            });

            if (videojs.browser.IS_ANY_SAFARI) {
                let muteButtonEl = videoElement.getElementsByClassName("vjs-mute-control vjs-control vjs-button")[0] as HTMLButtonElement;
                if (muteButtonEl) {
                    if (videojs.browser.IS_SAFARI) muteButtonEl.addEventListener("click", () => player.muted(!player.muted));
                    if (videojs.browser.IS_IOS) muteButtonEl.addEventListener("tap", () => player.muted(!player.muted()));
                }
            }
        } else {
            const player = playerRef.current;

            player.autoplay(options.autoplay);
            player.src(options.sources);
        }
    }, [options, videoRef]);

    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player>
            <div ref={videoRef} />
        </div>
    );
}