import { useTranslation } from "react-i18next";
import ShareCodeInput from "../components/home/shareCodeInput";
import IQLogo from "../components/shared/IQLogo";
import "./styles/home.css";

export default function Home() {
    const { t } = useTranslation();

    return (
        <div className="page-view home">
        <div className="container">
            <div className="d-flex justify-content-center">
            <div className="col-md-5 p-3 code-view">
                <div className="iq-logo">
                <IQLogo />

                <div className="d-flex justify-content-center mb-3">
                    <h5>{t("enter_code")}</h5>
                </div>
                </div>

                <div className="share-code-container mb-3">
                    <ShareCodeInput />
                </div>

            </div>
            </div>
        </div>
    </div>
    );
}