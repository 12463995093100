import { useEffect, useState } from "react"
import { AssetLink, Direction } from "../model/video.link"
import { useTranslation } from "react-i18next";

import "./styles/video.css";
import { VideoJS } from "../components/shared/videoJS";
import ShareableService from "../services/shareable.service";
import { useParams } from "react-router-dom";

export default function Video() {
    const { t } = useTranslation();
    const { code } = useParams();

    const [loading, setLoading] = useState(true);
    const [currentDirection, setCurrentDirection] = useState<Direction>(Direction.Front);
    const [views, setViews] = useState<AssetLink[]>([
        { title: t('front'), url: '', direction: Direction.Front, videoUrl: '' },
        { title: t('cabin'), url: '', direction: Direction.Cabin, videoUrl: '' },
        { title: t('rear'), url: '', direction: Direction.Rear, videoUrl: '' }
    ]);

    useEffect(() => {
        const rq1 = ShareableService().getLinks(code!);
        const rq2 = ShareableService().getVideos(code!);

        const viewsCopy = views.map(view => view);

        Promise.all([rq1, rq2])
            .then(([thumbnailsRes, videosRes]) => {
                viewsCopy.forEach(view => {
                    let thumbnail = thumbnailsRes.data.find(res => res.direction === view.direction);
                    if (thumbnail) view.url = thumbnail.url;

                    let video = videosRes.data.find(res => res.direction === view.direction);
                    if (video) view.videoUrl = video.url;
                });

                setViews(viewsCopy);
                setLoading(false);
            })
    }, [])

    function getView(direction: Direction): AssetLink | undefined {
        return views.find(view => view.direction === direction);
    }

    function setViewDirection(direction: Direction) {
        setCurrentDirection(direction);
    }

    function imageError(direction: Direction) {
        const viewsCopy = views.map(view => view);
        viewsCopy[direction].url = '';

        setViews(viewsCopy);
    }

    return (
        <div className="page-view video mt-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-12">
                        <div className="player-wrap mb-3">
                        <h5 className="mb-2 ms-2">{t("stream_title", {camera: getView(currentDirection)})}</h5>

                        {getView(currentDirection)?.videoUrl !== '' && <div className="view-box">
                            <VideoJS options={{
                                autoplay: true,
                                controls: true,
                                responsive: true,
                                fluid: true,
                                sources: [{
                                    src: getView(currentDirection)!.videoUrl,
                                    type: 'video/mp4'
                                }]
                            }} onReady={() => {}} />
                        </div>}
                        
                        {getView(currentDirection)?.videoUrl === '' && <div className="no-video d-flex align-items-center justify-content-center"> 
                            {!loading && <h6>{t("no_camera", {camera: getView(currentDirection)})}</h6>}
                            {loading && <div className="spinner-border text-white"></div>}
                        </div>}

                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="side-bar px-3 pt-1 pb-3">
                            {
                                views.filter(view => view.direction !== currentDirection).map((view) => 
                                <>
                                    <div className="view-wrap mt-3">
                                        <h6 className="mb-2">{t("stream_title", {camera: {title: view.title }})}</h6>
                                        {
                                            view.url === '' && 
                                            <div className="view-box not-avail d-flex align-items-center justify-content-center">
                                                <div>
                                                    {!loading && <h6>{t("no_camera", {camera: { title: view.title }})}</h6>}
                                                    {loading && <div className="spinner-border text-white"></div>}
                                                </div>
                                            </div>
                                        }
                                        {
                                            view.url !== '' &&
                                            <div className="view-box">
                                                <img crossOrigin="anonymous" src={view.url} className="img-fluid w-100" onClick={() => setViewDirection(view.direction)} onError={() => imageError(view.direction)} />
                                            </div>
                                        }
                                    </div>
                                </>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}