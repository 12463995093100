import Player from "video.js/dist/types/player";

export interface AssetLink {
  title: string;
  url: string;
  videoUrl: string;
  direction: Direction;
}

export enum Direction {
  Front = 0,
  Cabin = 1,
  Rear = 2,
}

export function parseStringToDirection(direction: string): Direction {
  switch (direction) {
    case 'cabin':
      return Direction.Cabin;
    case 'rear':
      return Direction.Rear;
    default:
      return Direction.Front;
  }
}

export function parseDirectionToString(direction: Direction): string {
  switch (direction) {
    case Direction.Cabin:
      return "cabin";
    case Direction.Front:
      return "front";
    case Direction.Rear:
      return "rear";
  }
}

export interface ValidateResponse {
  valid: boolean;
}

export interface StreamLink {
  title: string;
  streamUrl: string;
  direction: Direction;
  lastThumbnail: string;
}

export interface VideoPlayers {
  player: Player;
  direction: Direction;
}

export interface VideoApis {
  api: Player;
  direction: Direction;
}