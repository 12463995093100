import { useTranslation } from "react-i18next";

export default function Footer() {
    const { t } = useTranslation();

    return (
        <footer className="animated fadeInUp delay-3s">
            <a href="https://www.nextbase.com" target="_blank">{t("nextbase_website")}</a>
            <span>|</span>
            <a href="https://nextbase.co.uk/privacy-policy/" target="_blank">{t("privacy_policy")}</a>
            <span>|</span>
            <a href="https://www.nextbase.com/en-gb/contact-us/" target="_blank">{t("contact_us")}</a>
        </footer> 
    );
}