import axios, { AxiosResponse } from "axios";
import { AcceptInviteResponse, KinesisStream } from "../model/invitation";
import AppConfig from "../util/app.config";

export default function WitnessService() {
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    function acceptInvite(contactId: string): Promise<AxiosResponse<AcceptInviteResponse>> {
        return axios.get<AcceptInviteResponse>(`${AppConfig().witnessApi}/invite/accept/${contactId}`);
    }

    function getStreamUrls(sessionId: string): Promise<AxiosResponse<KinesisStream[]>> {
        return axios.get<KinesisStream[]>(`${AppConfig().witnessApi}/stream/get-stream-urls/${sessionId}`);
    }

    function getReplayUrls(sessionId: string, forceHls: boolean): Promise<AxiosResponse<KinesisStream[]>> {
        return axios.get<KinesisStream[]>(`${AppConfig().witnessApi}/stream/${forceHls ? 'get-hls-replay-stream-urls' : 'get-replay-stream-urls'}/${sessionId}`);
    }

    function getIsSessionActive(sessionId: string): Promise<AxiosResponse<boolean>> {
        return axios.get<boolean>(`${AppConfig().witnessApi}/stream/is-active/${sessionId}`);
    }

    return {
        acceptInvite,
        getStreamUrls,
        getReplayUrls,
        getIsSessionActive
    };
}