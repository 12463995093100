import { Navigate, createBrowserRouter, redirect } from "react-router-dom";
import Home from "./routes/home";
import Root from "./routes/root";
import NotFound from "./routes/notFound";
import Video from "./routes/video";
import WitnessLive from "./routes/witness/witness.live";
import WitnessReplay from "./routes/witness/witness.replay";
import WitnessInvite from "./routes/witness/witness.invite";
import Auth from "./routes/auth";
import FwUpdate from "./routes/fw-update/fw.update";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <NotFound />,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/video/:code",
                element: <Video />
            },
            {
                path: "/witness/:sessionId",
                element: <WitnessLive />
            },
            {
                path: "/witness/replay/:sessionId",
                element: <WitnessReplay />
            },
            {
                path: "/witness/accept/:contactId",
                element: <WitnessInvite />
            },
            {
                path: "/auth",
                element: <Auth />
            },
            {
                path: "/more-screen/dc-settings/fw-landing",
                element: <FwUpdate />
            }
        ]
    }
]);

export default router;