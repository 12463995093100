export default function IQLogo() {
    return (
        <>
        <div className="d-flex justify-content-center">
            <svg width="120" height="121" viewBox="0 0 120 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_27259_153469)">
                    <path d="M89.7486 86.5263C92.7317 88.8992 95.9995 90.4992 99.9995 90.4992V99.5025C92.7995 99.5025 87.5113 96.3975 82.9961 92.4517C80.7588 93.9975 78.3723 95.3127 75.8503 96.3839C70.9689 98.4449 65.7893 99.5025 60.4469 99.5025C55.1045 99.5025 49.9249 98.4585 45.0435 96.3839C41.8706 95.0415 38.9147 93.3059 36.2164 91.2178V78.6619C41.8164 85.9025 50.5757 90.5534 60.4334 90.5534C66.3181 90.5534 71.8232 88.8856 76.4876 86.011C71.5384 80.8042 66.8876 76.072 60.4334 76.072V67.0686C71.1723 67.0686 77.4096 73.9703 83.3079 80.289C88.1215 74.8788 91.0503 67.7602 91.0503 59.95C91.0503 43.0415 77.3554 29.3466 60.4469 29.3466C46.1961 29.3466 34.2232 39.0822 30.8062 52.2619C29.2198 51.3398 27.3757 50.8246 25.4232 50.8246C24.1622 50.8246 22.9418 51.0415 21.8164 51.4483C22.3317 49.1025 23.0639 46.7975 24.013 44.5602C26.0062 39.8551 28.8537 35.6246 32.4876 31.9907C36.1215 28.3568 40.352 25.5093 45.0571 23.5161C49.9384 21.4551 55.1181 20.3975 60.4605 20.3975C65.7893 20.3975 70.9825 21.4415 75.8639 23.5161C80.569 25.5093 84.7995 28.3568 88.4334 31.9907C92.0673 35.6246 94.9147 39.8551 96.9079 44.5602C98.9689 49.4415 100.013 54.6212 100.013 59.9636C100.013 65.3059 98.9689 70.4856 96.9079 75.367C95.1452 79.4754 92.7452 83.2178 89.7486 86.5263Z" fill="white"/>
                    <path d="M30.8339 94.0925C30.8339 97.0755 28.4068 99.5026 25.4237 99.5026C22.4407 99.5026 20.0136 97.0755 20.0136 94.0925H20V71.0552C21.6 71.9772 23.4441 72.506 25.4237 72.506C27.3898 72.506 29.2339 71.9772 30.8339 71.0552C30.8339 77.7806 30.8339 94.0247 30.8339 94.0925Z" fill="#FFC107"/>
                    <path d="M30.8321 61.6719C30.8321 64.655 28.4049 67.0821 25.4219 67.0821C22.4253 67.0821 20.0117 64.655 20.0117 61.6719C20.0117 58.6889 22.4388 56.2618 25.4219 56.2618C28.4049 56.2482 30.8321 58.6753 30.8321 61.6719Z" fill="#FFC107"/>
                </g>
                <defs>
                    <clipPath id="clip0_27259_153469">
                        <rect width="80" height="79.1051" fill="white" transform="translate(20 20.3975)"/>
                    </clipPath>
                </defs>
            </svg>              
        </div>
        </>
    );
}