import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { VideoJS } from "src/app/components/shared/videoJS";
import { Direction, StreamLink, parseStringToDirection } from "src/app/model/video.link";
import WitnessService from "src/app/services/witness.service";

import "./styles/witness.live.css";
import Player from "video.js/dist/types/player";

export default function WitnessLive() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { sessionId } = useParams();

    const [finished, setFinished] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadError, setLoadError] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    if (loadError) throw new Response("Not Found", { status: 404 });

    function teardown() {
        document.querySelector("body")!.className = "default-body";
    }

    useEffect(() => {
        const interval = setInterval(() => {
            WitnessService().getIsSessionActive(sessionId!)
                .then(res => {
                    if (!res.data) {
                        setFinished(true);

                        if (searchParams.size > 0) navigate(`/witness/replay/${sessionId}?${searchParams.toString()}`);
                        else navigate(`/witness/replay/${sessionId}`);
                    }
                })
        }, 5000);
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        document.querySelector("body")!.className = "dark-body";
        const viewsCopy = views.map(view => view);

        WitnessService().getStreamUrls(sessionId!)
            .then(res => {
                res.data.forEach(stream => {
                    let idx = viewsCopy.findIndex(view => view.direction === parseStringToDirection(stream.direction));
                    if (idx > -1) {
                        viewsCopy[idx].streamUrl = stream.streamURL;
                    }
                });

                setViews(viewsCopy);
                setLoading(false);
            })
            .catch(err => {
                if (err.response.status === 404) {
                    if (searchParams.size > 0) navigate(`/witness/replay/${sessionId}?${searchParams.toString()}`);
                    else navigate(`/witness/replay/${sessionId}`);
                } else {
                    setLoadError(true);
                }
            });
        
        return () => teardown();
    }, [sessionId]);

    const [views, setViews] = useState<StreamLink[]>([
        { title: t('front'), lastThumbnail: '', direction: Direction.Front, streamUrl: '' },
        { title: t('cabin'), lastThumbnail: '', direction: Direction.Cabin, streamUrl: '' },
        { title: t('rear'), lastThumbnail: '', direction: Direction.Rear, streamUrl: '' }
    ]);

    function getView(direction: Direction): StreamLink | undefined {
        return views.find(view => view.direction === direction);
    }

    function onPlayerReady(player: Player) {
        player.play();

        player.on('error', () => {
            if (searchParams.size > 0) navigate(`/witness/replay/${sessionId}?${searchParams.toString()}`);
            else navigate(`/witness/replay/${sessionId}`);
        });
    }

    return (
        <div className="page-view witness-live mt-4">
            <div className="container">
            
            <div className="row">
                <div className="col-md-8 col-12">
                
                <div className="player-wrap witness-live mb-3">
                    <h5 className="mb-2 ms-2">{t("stream_title", {camera: getView(Direction.Front)})}</h5>
        
                    {
                        getView(Direction.Front)?.streamUrl !== '' && 
                            <div className="view-box spotlight">
                                <VideoJS options={{
                                    autoplay: "muted",
                                    controls: true,
                                    fluid: true,
                                    responsive: true,
                                    sources: [
                                        { src: getView(Direction.Front)!.streamUrl, type: 'application/x-mpegURL' }
                                    ]
                                }} onReady={player => onPlayerReady(player)} canPause={false} />
                                {
                                    finished && 
                                        <div className="dim-box">
                                            <div className="finished-header-container">
                                                <h5 className="finished-header">{t("witness_stopped")}</h5>
                                            </div>
                                            <div className="finished-text-container">
                                                <p className="finished-text">{t("watch_again")}<Link to="/">iq.nextbase.com</Link>{t("watch_again_2")}</p>
                                            </div>
                                        </div>
                                }
                                {
                                    !finished && 
                                        <div className="live-banner">
                                            <span className="dot dot-live"></span>
                                            <span>{t("live")}</span>
                                        </div>
                                }
                            </div>
                    }
                    
                    {getView(Direction.Front)?.streamUrl === '' && <div className="no-video d-flex align-items-center justify-content-center"> 
                    {!loading && <h6>{t("no_camera", {camera: getView(Direction.Front)})}</h6>}
                    {loading && <div className="spinner-border text-white"></div>}
                    </div>}
        
                </div>
                    
                </div>
                <div className="col-md-4 col-12">
                    <div className="side-bar px-3 pt-1 pb-3">
                        <div className="view-wrap mt-3">
                            <h6 className="mb-2">{t("stream_title", {camera: getView(Direction.Cabin)})}</h6>
                            {
                                getView(Direction.Cabin)?.streamUrl !== '' && 
                                    <div className="view-box">
                                        <VideoJS options={{
                                            autoplay: "muted",
                                            controls: false,
                                            fluid: true,
                                            responsive: true,
                                            sources: [
                                                { src: getView(Direction.Cabin)!.streamUrl, type: 'application/x-mpegURL' }
                                            ]
                                        }} onReady={player => onPlayerReady(player)} />

                                        {finished && <div className="dim-box"></div>}
                                    </div>
                            }
                            {
                                getView(Direction.Cabin)?.streamUrl === '' && 
                                    <div className="not-avail d-flex align-items-center justify-content-center"> 
                                        {!loading && <h6>{t("no_camera", {camera: getView(Direction.Cabin)})}</h6>}
                                        {loading && <div className="spinner-border text-white"></div>}
                                    </div>
                            }
                        </div>
                        <div className="view-wrap mt-3">
                            <h6 className="mb-2">{t("stream_title", {camera: getView(Direction.Rear)})}</h6>
                            {
                                getView(Direction.Rear)?.streamUrl !== '' && 
                                    <div className="view-box">
                                        <VideoJS options={{
                                            autoplay: "muted",
                                            controls: false,
                                            fluid: true,
                                            responsive: true,
                                            sources: [
                                                { src: getView(Direction.Rear)!.streamUrl, type: 'application/x-mpegURL' }
                                            ]
                                        }} onReady={player => onPlayerReady(player)} />

                                        {finished && <div className="dim-box"></div>}
                                    </div>
                            }
                            {
                                getView(Direction.Rear)?.streamUrl === '' && 
                                    <div className="not-avail d-flex align-items-center justify-content-center"> 
                                        {!loading && <h6>{t("no_camera", {camera: getView(Direction.Rear)})}</h6>}
                                        {loading && <div className="spinner-border text-white"></div>}
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}